import React, { Component } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import Header from "../components/header"
import Footer from "../components/footer"
import Testimonial from "../components/testimonial";
import StickyButton from "../components/sticky-button";
import SEO from "../components/seo";
import "../style/bootstrap.min.css";
import "../style/style.css";
import Jumbotron from 'react-bootstrap/Jumbotron';
import Button from 'react-bootstrap/Button';

class Page extends Component {
  render() {
    const StaticPage = this.props.data.wordpressPage

    return (
      <>
        <Header />
        <Jumbotron>
          <div className="page-header">
          <h1 className="ContentTitle" >{StaticPage.title}</h1>
          </div>
  
</Jumbotron>
      <div class="container PageContent">
        <div className="ContentDescription" dangerouslySetInnerHTML={{ __html: StaticPage.content }} />
        </div>
        <Testimonial />
        <Footer />
        <StickyButton />
      </>
    )
  }
}

export default Page

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
    }
    site {
      id
      siteMetadata {
        title
        description
      }
    }
  }
`